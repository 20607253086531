import { GridColDef } from "@mui/x-data-grid";

export const columns: GridColDef[] = [
  {
    field: "descripcion",
    headerAlign: "left",
    headerName: "Descripción",
    width: 120,
    align: "left",
    sortable: true,
    flex: 1,
    valueGetter: (params) => {
      if (params.row?.descripcion) return params.row.descripcion;
    },
  },
  {
    field: "expediente",
    headerName: "Expediente",
    width: 120,
    sortable: true,
    filterable: false,
    valueGetter: (params) => {
      if (params.row?.expediente) return params.row.expediente;
    },
  },
  {
    field: "observacion",
    headerName: "Observacion",
    width: 120,
    sortable: true,
    flex: 1,
    filterable: false,
    valueGetter: (params) => {
      if (params.row?.observacion) return params.row.observacion;
    },
  },
  {
    field: 'created_at',
    headerName: 'Fecha de Alta',
    type: 'date',
    width: 105,
    align: 'center',
    headerAlign: 'center',
    valueFormatter: (params) => {
      return params.value && new Date(params.value).toLocaleString('es-AR', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      });
    }
  },
];
