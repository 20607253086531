import { Grid, IconButton, InputAdornment, Link } from "@mui/material";
import LoginIcon from '@mui/icons-material/Login';
import { useAuth, SimpleCard, TextInput, FormButton } from "stj-components";
import { Form, Formik } from "formik";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { validationSchema } from "./validation";
import { useEffect, useState } from "react";
import toast from 'react-hot-toast';

const SignupPage = () => {

  const { signUp, error } = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '', 
  }

  useEffect(() => {
    //TODO: acá debería ir el mensaje de error que viene de la API cuando esté estandarizado
    toast.error("Ocurrió un error intente más tarde");
  }, [ error ])
  
  const handleSetPassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <SimpleCard title="Registro" textAlign="center" maxWidth={400}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          signUp(values.email, values.firstName, values.password)
          setSubmitting(false);
        }}
      >
        <Form>
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <TextInput
                name="firstName"
                label="Nombre"
                placeholder="Escriba su nombre"
                autoFocus={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                label="Apellido"
                name="lastName"
                placeholder="Escriba su apellido"
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                label="Email"
                name="email"
                placeholder="Escriba su nombre de usuario"
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                name="password"
                label="Contraseña"
                placeholder="Escriba su contraseña"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleSetPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Grid>
          </Grid>
          <FormButton
            text="Registro"
            type="submit"
            startIcon={ <LoginIcon /> }
            variant="contained"
          />
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="login" variant="body2">
                ¿Ya tenés cuenta? Iniciar Sesión.
              </Link>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </SimpleCard>
  );
}

export default SignupPage;