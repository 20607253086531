import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const RestrictedPage = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="md">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        spacing={3}
      >
        <Grid item xs={12} md={8} lg={9}>
          <Paper sx={{ p: 3 }}>
            <Typography align="center" color="textPrimary" variant="subtitle1">
              No tienes permisos suficientes para visitar esta página.
            </Typography>
            <Button
              component="a"
              startIcon={<ArrowBackIcon fontSize="small" />}
              sx={{ mt: 3 }}
              variant="contained"
              onClick={() => navigate("/")}
            >
              Volver al home
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default RestrictedPage;
