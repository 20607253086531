import Grid from "@mui/material/Grid";
import { Form, Formik } from "formik";
import { ComboBox, FormButton, TextInput } from "stj-components";
import * as Yup from "yup";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import { useGetPerfiles } from "../../../services/api/perfiles";

const initialValues = {
  nombre: "",
  apellido: "",
  documento: "",
  email: "",
  perfilIds: [],
};

interface SearchPostulanteProps {
  handleOnSubmit: (data: Array<any>) => void;
}
const SearchPostulante = (props: SearchPostulanteProps) => {
  const { handleOnSubmit } = props;

  const { data: perfiles } = useGetPerfiles();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object({
        documento: Yup.string(),
        apellido: Yup.string(),
        nombre: Yup.string(),
        email: Yup.string(),
        perfilIds: Yup.array().of(new Yup.StringSchema()).nullable(),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          handleOnSubmit(values as any);
          setSubmitting(false);
        }, 500);
      }}
    >
      {(formik) => (
        <Form>
          <Grid container spacing={1} sx={{ mt: 1 }}>
            {/* Fila superior */}
            <Grid item xs={12}>
              <ComboBox
                label="Perfiles"
                options={
                  perfiles?.map((perfil) => ({
                    label: `${perfil?.label} - ${perfil?.descripcion}`,
                    value: perfil?.id,
                  })) ?? []
                }
                name="perfilIds"
                multiple
                size="small"
                optionValue="value"
                optionLabel="label"
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextInput
                label="Apellido"
                name="apellido"
                type="text"
                placeholder="apellido"
                size="small"
                autoFocus={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextInput
                label="Nombre"
                name="nombre"
                type="text"
                placeholder="Nombre"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextInput
                label="DNI"
                name="documento"
                type="text"
                placeholder="DNI"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TextInput
                label="Email"
                name="email"
                type="text"
                placeholder="email"
                size="small"
                fullWidth
              />
            </Grid>

            <Grid
              container
              item
              spacing={1}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12} md={4} alignItems="right">
                <FormButton
                  text="Buscar"
                  type="submit"
                  variant="contained"
                  startIcon={<SearchOutlinedIcon />}
                  color="secondary"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormButton
                  text="Limpiar"
                  onClick={() => {
                    formik.resetForm();
                    formik.setFieldValue("perfilIds", []);
                    handleOnSubmit([]);
                  }}
                  variant="outlined"
                  startIcon={<CleaningServicesIcon />}
                  color="error"
                />
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default SearchPostulante;
