import { pathToUrl } from "../../utils/urlGenerator";
import { apiRoutes } from "./apiRoutes";
import { useReactQuery } from "stj-components";
import { PerfilInterface } from "../../interfaces/PerfilInterface";

const { useDelete, useFetch, usePost, useUpdate, useFormDataPost } =
  useReactQuery;

export const useGetPerfiles = (queryParams?: any) =>
  useFetch<PerfilInterface[]>(pathToUrl(apiRoutes.perfiles), queryParams);
