import { pathToUrl } from "../../utils/urlGenerator";
import {
  GeneroInterface,
  PaginatedGeneroInterface,
} from "../../interfaces/GeneroInterface";
import { apiRoutes } from "./apiRoutes";
import { useReactQuery } from "stj-components";

const { useFetch } = useReactQuery;

export const useGetGeneros = (queryParams?: any) =>
  useFetch<GeneroInterface[]>(pathToUrl(apiRoutes.generos), queryParams);

export const useGetPaginatedGeneros = (queryParams: any) =>
  useFetch<PaginatedGeneroInterface>(
    pathToUrl(apiRoutes.generos, {}),
    queryParams
  );

export const useGetGenero = (id: number) =>
  useFetch<GeneroInterface>(pathToUrl(apiRoutes.generosById, { id }));
