import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid';
import { ContextualButton } from 'stj-components';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import { RestrictedComponent } from "src/auth/RestrictedComponent";
import { ROLE } from "src/auth/Role.enum";


export interface MenuOption {
  action?: (id: any) => void;
  children?: React.ReactNode | string;
  disabled?: boolean;
}

export const GridMenuOption: GridColDef = {
  field: 'actions',
  headerName: ' ',
  width: 50,
  align: 'center',
  headerAlign: 'center',
  renderCell: (params: GridRenderCellParams<boolean>) => {
    const options: MenuOption[] = [
      {
        children: (
          <>
            <OpenInNewIcon />
            Ver
          </>
        ),
        action: () => {
          params.row.handleViewModalToggle(params.row);
        }
      },
      {
        children: (
          <>
            <EditIcon />
            Editar
          </>)
        ,
        action: () => {
          params.row.handleEditModalToggle(params.row);
        }
      },
      {
        children: (
          <>
            <DeleteIcon />
            Eliminar
          </>
        ),
        action: () => {
          params.row.handleDeleteModalToggle(params.row);
        }
      },
    ];

    return <ContextualButton options={options}></ContextualButton>;
  }
};





