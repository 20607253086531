import { Modal } from 'stj-components';
import { Button } from '@mui/material';
import React from 'react'

interface CrudDeleteModalProps {
  open: boolean;
  handleMutation: () => void;
  handleClose: () => void;
}

const CrudDeleteModal = (props: CrudDeleteModalProps) => {
  
  const { open, handleMutation, handleClose } = props;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleMutation();
    handleClose();
  }
  
  return (
    <React.Fragment>
      <Modal
        title="Eliminar registro"
        open={open}
        handleClose={handleClose}
      >
        <form action="" onSubmit={handleSubmit}>
          <Button
            variant="contained"
            type="submit"
            color="error"
            fullWidth
            >
            Eliminar
          </Button>
        </form>
      </Modal>
    </React.Fragment>
  )
}

export default CrudDeleteModal;