import { useState } from "react";
import { Box } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import Homepage from "./pages/HomePage/HomePage";
import { LayoutLogin, ProtectedRoute } from "stj-components";
import { QueryClient, QueryCache, QueryClientProvider } from "react-query";
import toast, { Toaster } from "react-hot-toast";
import NoMatch from "./pages/NoMatch";
import LoginPage from "./pages/LoginPage/LogInpage";
import SignupPage from "./pages/SignupPage/SignupPage";
import { appComponents } from "./appComponents";
import { RestrictedRoute } from "./auth/RestrictedRoute";
import { Layout } from "./Layout";
import ModalResetPassword from "./pages/HomePage/ModalResetPassword";

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    // background update failure
    onError: (error, query) => {
      if (query.state.data !== undefined && error instanceof Error) {
        toast.error(
          `${error.message} error buscando los datos, por favor intente en unos momentos`
        );
      }
    },
  }),
});

function App() {
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

  const resetPassword = () => {
    // open modal to reset password
    setShowResetPasswordModal(true);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <Box>
        {/* Las routes se anidan unas dentro de otras.
          Los paths de rutas anidadas se construyen con los paths de los routes padres.
          Y los elementos de route anidados se renderizan
          dentro de los elementos de routes padre. */}
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route
              path="/"
              element={<Layout openResetPasswordModal={resetPassword} />}
            >
              <Route index element={<Homepage />} />

              {appComponents.map((ruta, idx) => (
                <Route
                  key={idx}
                  element={
                    <RestrictedRoute
                      roles={ruta.rolesRequired}
                      permissions={ruta.permsRequired}
                    />
                  }
                >
                  <Route path={ruta.path} element={ruta.element} />
                </Route>
              ))}

              <Route path="*" element={<NoMatch />} />
            </Route>
          </Route>

          <Route
            path="/"
            element={
              <LayoutLogin
                title={
                  process.env.REACT_APP_MODE === "dev"
                    ? "HAIN - DESARROLLO"
                    : "HAIN"
                }
                subTitle={""}
                appBarItems={[]}
              />
            }
          >
            <Route path="login" element={<LoginPage />} />
            <Route path="registro" element={<SignupPage />} />
          </Route>
        </Routes>
        <Toaster />
      </Box>

      <ModalResetPassword
        show={showResetPasswordModal}
        handleClose={() => setShowResetPasswordModal(false)}
      />
    </QueryClientProvider>
  );
}

export default App;
