import {
  useCreateConvocatoriaCalendario,
  useGetConvocatoriasCalendarios,
  useUpdateConvocatoriaCalendario,
  useDeleteConvocatoriaCalendario,
} from '../../../../services/api/ConvocatoriaCalendario';
import GenericCrud from "../../../../components/crud/GenericCrud";
import { CircularProgress } from '@mui/material';
import { apiRoutes } from '../../../../services/api/apiRoutes';
import { columnsConvocatoriaCalendario } from './columnsConvocatoriaCalendario';
import { FormConvocatoriaCalendario } from './FormConvocatoriaCalendario';
import { validationConvocatoriaCalendario } from "./validationConvocatoriaCalendario";
import { ConvocatoriaCalendarioAddOrUpdateInterface } from "../../../../interfaces/ConvocatoriaCalendarioInterface";
import { ConvocatoriaInterface } from '../../../../interfaces/ConvocatoriaInterface';

interface TabConvocatoriaCalendarioProps {
  convocatoria: ConvocatoriaInterface;
}

const TabConvocatoriaCalendario = (props: TabConvocatoriaCalendarioProps) => {
  const { convocatoria } = props;
  const { data, isError, refetch } = useGetConvocatoriasCalendarios({
    'filter[convocatoria_id]': convocatoria.id,
    include: 'actividad',
    sort: 'fecha_inicio'
  });
  const addMutation = useCreateConvocatoriaCalendario();
  const deleteMutation = useDeleteConvocatoriaCalendario();

  const initValues: ConvocatoriaCalendarioAddOrUpdateInterface = {
    convocatoria_id: convocatoria.id
  };

  if (data) {
    return (
      <GenericCrud
        data={data}
        columns={columnsConvocatoriaCalendario}
        form={<FormConvocatoriaCalendario />}
        formValidation={validationConvocatoriaCalendario}
        formInitialValues={initValues}
        addMutation={addMutation}
        useEditMutation={useUpdateConvocatoriaCalendario}
        deleteMutation={deleteMutation}
        queryToInvalidate={apiRoutes.convocatoriaCalendarios}
        refetch={refetch}
      />
    )
  }

  if (isError) {
    return (
      <div>Error cargando</div>
    )
  }

  return (
    <CircularProgress />
  )
}

export default TabConvocatoriaCalendario;