import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import { Person, Settings, TypeSpecimenSharp } from "@mui/icons-material";
import Search from "@mui/icons-material/Search";
import Travel from "@mui/icons-material/TravelExplore";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import { appRoutes } from "./appRoutes";
import { ROLE } from "./auth/Role.enum";
import { PERMISSION } from "./auth/Permissions.enum";
import { ReactNode } from "react";

// export type link = {
//   path: string;
//   name: string;
//   icon: JSX.Element;
// };

// export interface option {
//   label: string;
//   options: Array<link | option>;
//   isSubMenu?: boolean;
//   key: string;
//   badge?: number | string;
// }

// export const instanceOfOption = (object: any): object is option => {
//   return "options" in object;
// }; // https://stackoverflow.com/a/14426274

interface Option {
  label?: string;
  path?: string;
  name?: string;
  icon?: ReactNode;
  key?: string;
  rolesRequired?: Array<ROLE>;
  permsRequired?: Array<PERMISSION>;
  options?: Option[];
}

export const optionsList: Array<Option> = [
  {
    label: "Convocatorias",
    options: [
      {
        path: "/convocatorias/crear",
        name: "Nueva",
        icon: <AddOutlinedIcon />,
        key: "elecciones.nueva",
      },    
      {
        path: "/convocatorias/listar",
        name: "Listado",
        icon: <ListOutlinedIcon />,
        key: "elecciones.listar",
      },
  //     {
  //       path: "/actividades/listar",
  //       name: "Actividades",
  //       icon: <ListOutlinedIcon />,
  //       key: "actividades.listar",
  //     },
  //     {
  //       path: "/elecciones/alianzas",
  //       name: "Candidatos (Alnz)",
  //       icon: <Person />,
  //       key: "elecciones.consultaPartidosCargos",
  //     },
  //     {
  //       path: "/elecciones/partidos",
  //       name: "Candidatos (Part)",
  //       icon: <Person />,
  //       key: "elecciones.consultaPartidosCargos",
  //     },        
  //     {
  //       path: "/delegados/listar",
  //       name: "Delegados",
  //       icon: <Person />,
  //       key: "delegados.listarDelegados",
  //     },
  //     {
  //       path: "/estadisticas/padron-definitivo",
  //       name: "Estadisticas",
  //       icon: <SignalCellularAltIcon />,
  //       key: "estadisticas.listarEstadisticasPadronDefinitivo",
  //     },
  //     {
  //       path: "/elecciones/establecimientos-circuitos",
  //       name: "Establecimientos",
  //       icon: <Travel />,
  //       key: "elecciones.establecimientosCircuitos",
  //     },
  //     {
  //       path: "/elecciones/padron-definitivo",
  //       name: "Padron Definitivo",
  //       icon: <Search />,
  //       key: "elecciones.consultaPadronDefinitivo",
  //     },
  //     {
  //       path: "/elecciones/padron-provisorio",
  //       name: "Padron Provisorio",
  //       icon: <Search />,
  //       key: "elecciones.consultaPadronProvisorio",
  //     },
    ],
  },
  {
    label: "Postulantes",
    options: [   
      {
        path: "/postulantes/listar",
        name: "Gestión",
        icon: <ListOutlinedIcon />,
        key: "postulantes.listar",
        // rolesRequired: [ROLE.ADMINISTRADOR, ROLE.ADMIN_NIVEL_1],
        // permsRequired: [PERMISSION.PERMISO_1, PERMISSION.PERMISO_4],
      },
    ],
  },
  // {
  //   label: "Establecimientos",
  //   options: [
  //     {
  //       path: "/establecimientos/listar",
  //       name: "Listado",
  //       icon: <ListOutlinedIcon />,
  //       key: "key2",
  //     },
  //   ],
  // },
  // {
  //   label: "Infractores",
  //   options: [
  //     {
  //       path: appRoutes.imprimirConstancia,
  //       name: "Constn No Infrct",
  //       icon: <ListOutlinedIcon />,
  //       key: "key3",
  //     },
  //     {
  //       path: "/infractores/importar",
  //       name: "Importar",
  //       icon: <ListOutlinedIcon />,
  //       key: "key3",
  //     },
  //     {
  //       path: "/infractores/listar",
  //       name: "Listado",
  //       icon: <ListOutlinedIcon />,
  //       key: "key2",
  //     },
  //     {
  //       path: "/infractores/listar/migrado",
  //       name: "Migrados",
  //       icon: <ListOutlinedIcon />,
  //       key: "key2",
  //     },
  //     // {
  //     //   path: "/infractores/reclamos/listar",
  //     //   name: "Reclamos",
  //     //   icon: <ListOutlinedIcon />,
  //     //   key: "key3",
  //     // },
     
     
  //   ],
  // },
  // {
  //   label: "Ocupaciones",
  //   options: [
  //     {
  //       path: "/ocupaciones/listar",
  //       name: "Listado",
  //       icon: <ListOutlinedIcon />,
  //       key: "key1",
  //     },
  //   ],
  // },
  // {
  //   label: "Partidos",
  //   options: [
  //     {
  //       path: "/partidos/listar",
  //       name: "Listado",
  //       icon: <ListOutlinedIcon />,
  //       key: "key1",
  //     },
  //     {
  //       path: "/partidos/crear",
  //       name: "Nuevo",
  //       icon: <AddOutlinedIcon />,
  //       key: "key1",
  //     },
  //   ],
  // }, 
  // {
  //   label: "Personas",
  //   options: [
     
  //     {
  //       path: "/personas/crear",
  //       name: "Crear",
  //       icon: <AddOutlinedIcon />,
  //       key: "key4",
  //     },
      
  //     {
  //       path: "/estadisticas/personas",
  //       name: "Estadísticas",
  //       icon: <SignalCellularAltIcon />,
  //       key: "key5",
  //     },
  //     {
  //       path: "/personas/extranjeras",
  //       name: "Extranjeros",
  //       icon: <Person />,
  //       key: "key5",
  //     },
  //     {
  //       path: "/estadisticas/info-personas-activas",
  //       name: "Info Dinámica",
  //       icon: <SignalCellularAltIcon />,
  //       key: "key5",
  //     },
  //     {
  //       path: "/personas/listar",
  //       name: "Listado",
  //       icon: <ListOutlinedIcon />,
  //       key: "key1",
  //     },
      
  //     {
  //       path: "/personas/renaper",
  //       name: "RENAPER",
  //       icon: <Person />,
  //       key: "key5",
  //     }, 
  //     {
  //       path: "/personas/reportes",
  //       name: "Reportes",
  //       icon: <PictureAsPdf />,
  //       key: "key6",
  //     },
  //   ],
  // }, 
  // {
  //   label: "Tipos Inhabilitación",
  //   options: [
  //     {
  //       path: "/tipos-inhabilitacion/listar",
  //       name: "Listado",
  //       icon: <ListOutlinedIcon />,
  //       key: "key1",
  //     },
  //   ],
  // }, 
  {
    label: "Usuarios/Roles",
    options: [
      {
        path: "/usuarios/listar",
        name: "Listado",
        icon: <ListOutlinedIcon />,
        key: "key1",
      },
    ],
  },
];
