import { pathToUrl } from "../../utils/urlGenerator";
import {
  TipoDiscapacidadInterface,
  PaginatedTipoDiscapacidadInterface,
} from "../../interfaces/TipoDiscapacidadInterface";
import { apiRoutes } from "./apiRoutes";
import { useReactQuery } from "stj-components";

const { useFetch } = useReactQuery;

export const useGetTipoDiscapacidades = (queryParams?: any) =>
  useFetch<TipoDiscapacidadInterface[]>(
    pathToUrl(apiRoutes.tiposDiscapacidades),
    queryParams
  );

export const useGetPaginatedTipoDiscapacidades = (queryParams: any) =>
  useFetch<PaginatedTipoDiscapacidadInterface>(
    pathToUrl(apiRoutes.tiposDiscapacidades, {}),
    queryParams
  );

export const useGetTipoDiscapacidad = (id: number) =>
  useFetch<TipoDiscapacidadInterface>(
    pathToUrl(apiRoutes.tiposDiscapacidadesById, { id })
  );
