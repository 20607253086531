import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { GridMenuOption } from "../../../../components/crud/GridMenuOption";
import { formatDateAR } from "../../../../utils/utils"

export const columnsConvocatoriaCalendario: GridColDef[] = [
  GridMenuOption,
  // {
  //   field: 'id',
  //   headerName: 'Nro.',
  //   width: 50,
  //   renderCell: (params: GridRenderCellParams<boolean>) => (
  //     params.row.actividad.id
  //   ),
  // },
  {
    field: 'fecha_inicio',
    headerName: 'Fecha Inicio',
    type: 'date',
    width: 125,
    valueFormatter: (params) => {

      return formatDateAR(params?.value);
    }
  },
  {
    field: 'actividad',
    headerName: 'Actividad',
    width: 350,
    renderCell: (params: GridRenderCellParams<boolean>) => (
      params.row.actividad.descripcion
    ),
  },
  {
    field: 'fecha_fin',
    headerName: 'Fecha fin',
    type: 'date',
    width: 125,
    valueFormatter: (params) => {
      return formatDateAR(params?.value);
    }
  },
  {
    field: 'observaciones',
    headerName: 'observaciones',
    width: 400,
    align: 'center',
    headerAlign: 'center',
  },
];
