import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { FileUpload } from "@mui/icons-material";
import toast from "react-hot-toast";
import { ErrorMessage } from "formik";
import { FileButtonProps } from "./FileButton.types";

export const FileButton = (props: FileButtonProps) => {
  const { name, text, setFieldValue, accept, value } = props;
  const [currentFile, setCurrentFile] = useState<File | string>("");

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.currentTarget.files || event.currentTarget.files.length === 0) {
      toast.error("No seleccionaste ningún archivo");
    } else {
      const file = event.currentTarget.files[0];
      // para mostrar el nombre
      setCurrentFile(file);
      // para que lo reciba el formulario de formik
      setFieldValue(name, file);
    }
  };

  return (
    <Grid
      container
      alignContent="stretch"
      justifyContent="center"
      direction="column"
    >
      <Grid item xs={12}>
        <input
          name={name}
          accept={accept}
          id={name}
          type="file"
          style={{ display: "none" }}
          onChange={handleOnChange}
          // required={required}
        />
        <label htmlFor={name}>
          <Button
            variant="outlined"
            color="secondary"
            component="span"
            startIcon={<FileUpload />}
            fullWidth
          >
            {text}
          </Button>
        </label>
        <div style={{ color: "red", fontSize: "0.75rem" }}>
          <ErrorMessage name={name} />
        </div>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="span"
          style={{ color: "#363636", fontSize: "0.75rem" }}
        >
          Archivo seleccionado:
        </Typography>{" "}
        <Typography
          variant="subtitle2"
          gutterBottom
          component="span"
          style={{ color: "#363636", fontSize: "0.75rem" }}
        >
          {value ? value.name : "Ninguno"}
          {/* {typeof currentFile === "string" ? "Ninguno" : currentFile?.name} */}
        </Typography>
      </Grid>
    </Grid>
  );
};
