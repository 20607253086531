import { Grid, IconButton, InputAdornment } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import { useAuth, SimpleCard, TextInput, FormButton } from "stj-components";
import { Form, Formik } from "formik";
import { validationSchema } from "./validation";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const LoginPage = () => {
  const { login, error } = useAuth();
  const [showPassword, setShowPassword] = useState(false);

  const initialValues = {
    username: "",
    password: "",
  };

  useEffect(() => {
    if (error) {
      //TODO: acá debería ir el mensaje de error que viene de la API cuando esté estandarizado
      toast.error("error, revise usuario o contraseña");
    }
  }, [error]);

  const handleSetPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <SimpleCard title="Ingreso al Sistema" textAlign="center" maxWidth={400}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          login(values.username, values.password);
          setSubmitting(false);
        }}
      >
        <Form>
          <Grid container spacing={1}>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <TextInput
                autoFocus={true}
                label="Email"
                name="username"
                placeholder="Escriba su Email"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 3 }}>
              <TextInput
                name="password"
                label="Contraseña"
                type={showPassword ? "text" : "password"}
                placeholder="Escriba su contraseña"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleSetPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                fullWidth
              />
            </Grid>
            <Grid item xs sx={{ mt: 2 }}>
              {/* <Link href="#" variant="body2">
                ¿Olvidaste la contraseña?
              </Link> */}
            </Grid>
            <Grid item xs={12}>
              <FormButton
                text="Iniciar Sesión"
                type="submit"
                startIcon={<LoginIcon />}
                variant="contained"
              />
            </Grid>
            <Grid item xs={12}>
              {/* <Divider variant="middle" />
            <Link href="registro" variant="body2">
              {"¿No tenés cuenta? Registrate"}
            </Link> */}
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </SimpleCard>
  );
};

export default LoginPage;
