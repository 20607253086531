import { Outlet } from "react-router-dom";
import { useHasPerm, useHasRole } from "./hooks";
import RestrictedPage from "src/pages/RestrictedPage";

interface RestrictedRouteProps {
  roles?: string[];
  permissions?: string[];
}

export const RestrictedRoute = (props: RestrictedRouteProps) => {
  const { roles, permissions } = props;
  const { hasRole } = useHasRole(roles ?? []);
  const { hasPerm } = useHasPerm(permissions ?? []);

  return hasRole && hasPerm ? <Outlet /> : <RestrictedPage />;
};
