import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  DataGrid,
  GridColDef,
  esES,
  GridToolbar,
  GridSortModel,
  GridFilterModel,
  GridCallbackDetails,
} from "@mui/x-data-grid";
import { useQueryClient } from "react-query";
import CrudAddEditModal from "./CrudAddEditModal";
import CrudDeleteModal from "./CrudDeleteModal";
import { OptionalObjectSchema } from "yup/lib/object";
import toast from "react-hot-toast";

interface GenericCrudProps {
  data: any[];
  columns: GridColDef[];
  form: React.ReactNode;
  formEdit?: React.ReactNode;
  formInitialValues: any;
  formValidation: OptionalObjectSchema<any>;
  addMutation: any;
  useEditMutation: any;
  deleteMutation: any;
  handleAdd?: () => void;
  handleEdit?: () => void;
  handleDelete?: () => void;
  refetch?: any;
  queryToInvalidate?: string;
  hideAddBtn?: boolean;
  editOnClick?: boolean;
  viewOnClick?: boolean;
  components?: any;
  componentsProps?: any;
  page?: number;
  pageSize?: number;
  rowCount?: number;
  setPage?: any;
  setPageSize?: any;
  titleEditar?: string;
  handleSort?: (model: GridSortModel, details: GridCallbackDetails) => void;
  handleFilter?: (model: GridFilterModel, details: GridCallbackDetails) => void;
}

const GenericCrud = (props: GenericCrudProps) => {
  const {
    data,
    columns,
    form,
    formEdit,
    formInitialValues,
    formValidation,
    addMutation,
    useEditMutation,
    deleteMutation,
    handleAdd,
    handleEdit,
    handleDelete,
    queryToInvalidate,
    refetch,
    hideAddBtn,
    editOnClick = true,
    viewOnClick = true,
    components,
    componentsProps,
    page,
    pageSize,
    rowCount,
    setPage,
    setPageSize,
    titleEditar,
    handleSort,
    handleFilter,
  } = props;

  const [selectedRow, setSelectedRow] = useState<any>({ id: 0 });
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [editData, setEditData] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const editMutation = useEditMutation(selectedRow.id);

  const handleAddModalToggle = () => {
    setEditData(true);
    setOpenAddModal(!openAddModal);
  };

  const handleViewModalToggle = (row: any) => {
    setSelectedRow(row);
    setEditData(false);
    setOpenEditModal(!openEditModal);
  };

  const handleEditModalToggle = (row: any) => {
    setSelectedRow(row);
    setEditData(true);
    setOpenEditModal(!openEditModal);
  };

  const handleDeleteModalToggle = (row: any) => {
    setSelectedRow(row);
    setOpenDeleteModal(!openDeleteModal);
  };

  const handleAddSubmit = async (data: any) => {
    try {
      await addMutation.mutateAsync(data, {
        onSettled: () => {
          queryClient.invalidateQueries(queryToInvalidate);
          refetch();
        },
      });
    } catch (error) {
      toast.error(`Ocurrió un error: ${error}`);
    }
  };

  const handleEditSubmit = async (data: any) => {
    try {
      await editMutation.mutateAsync(data, {
        onSettled: () => {
          queryClient.invalidateQueries(queryToInvalidate);
          refetch();
        },
      });
    } catch (error) {
      toast.error(`Ocurrió un error: ${error}`);
    }
  };

  const handleDeleteSubmit = async () => {
    try {
      await deleteMutation.mutateAsync(selectedRow.id, {
        onSettled: () => {
          queryClient.invalidateQueries(queryToInvalidate);
          refetch();
        },
      });
    } catch (error) {
      toast.error(`Ocurrió un error: ${error}`);
    }
  };

  return (
    <Grid container spacing={1} flexDirection="column">
      {!hideAddBtn && (
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            onClick={handleAddModalToggle}
            startIcon={<AddIcon />}
          >
            Agregar
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        <DataGrid
          autoHeight
          getRowHeight={() => "auto"}
          getEstimatedRowHeight={() => 100}
          columns={columns}
          getRowId={(row: any) => row.id}
          rows={data.map((resource) => {
            return {
              ...resource,
              handleViewModalToggle,
              handleEditModalToggle,
              handleDeleteModalToggle,
            };
          })}
          density="compact"
          // components={{
          //   Toolbar: GridToolbar,
          // }}
          components={components}
          //componentsProps={componentsProps}

          componentsProps={{
            filterPanel: {
              filterFormProps: {
                operatorInputProps: {
                  disabled: true, // If you only want to disable the operator
                  sx: { display: "none" }, // If you want to remove it completely
                },
              },
            },
          }}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          onRowDoubleClick={(rowData) => {
            if (editOnClick) return handleEditModalToggle(rowData.row);
            if (viewOnClick) return handleViewModalToggle(rowData.row);
            return <></>;
          }}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          onPageChange={(newPage) => setPage(newPage)}
          rowsPerPageOptions={[10, 20, 30, 50, 100]}
          page={page}
          pagination
          rowCount={rowCount ?? data.length}
          disableDensitySelector
          paginationMode="server"
          sortingMode={handleSort ? "server" : "client"}
          {...(handleSort ? { onSortModelChange: handleSort } : {})}
          filterMode={handleFilter ? "server" : "client"}
          {...(handleFilter ? { onFilterModelChange: handleFilter } : {})}
        />
      </Grid>
      <CrudAddEditModal
        title="Agregar"
        open={openAddModal}
        edit={editData}
        form={form}
        formValidation={formValidation}
        formInitialValues={formInitialValues}
        handleMutation={handleAddSubmit}
        handleClose={handleAddModalToggle}
      />
      <CrudAddEditModal
        title={
          (editData ? "Editar " : "Detalles ") +
          (props.titleEditar ? props?.titleEditar : "")
        }
        open={openEditModal}
        edit={editData}
        form={formEdit ? formEdit : form}
        formValidation={formValidation}
        formInitialValues={selectedRow}
        handleMutation={handleEditSubmit}
        handleClose={() => setOpenEditModal(false)}
      />
      <CrudDeleteModal
        open={openDeleteModal}
        handleMutation={handleDeleteSubmit}
        handleClose={() => setOpenDeleteModal(false)}
      />
    </Grid>
  );
};

export default GenericCrud;
