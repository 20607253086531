export const baseUrlApi = process.env.REACT_APP_API_URL;

export const apiRoutes = {
  convocatorias: "convocatorias",
  convocatoriasById: "convocatorias/:id",

  actividades: "actividades",
  actividadesById: "actividades/:id",
  actividadesByConvocatoriasById: "convocatorias/:id/actividades",

  // TODO: pasar a plural
  convocatoriaCalendarios: "calendario-convocatoria",
  convocatoriaCalendariosById: "calendario-convocatoria/:id",

  generos: "generos",
  generosById: "generos/:id",

  ocupaciones: "ocupaciones",
  ocupacionesById: "ocupaciones/:id",

  tiposDiscapacidades: "tipos-discapacidades",
  tiposDiscapacidadesById: "tipos-discapacidades/:id",

  postulantes: "postulantes",
  postulantesById: "postulantes/:id",
  mailPostulanteConfirmar: "postulantes/:id/confirmar-email",
  reenviarEmailConfirmacion: "postulantes/:id/reenviar-email-confirmacion",

  perfiles: "perfiles",
};
