import { pathToUrl } from "../../utils/urlGenerator";
import { apiRoutes } from "./apiRoutes";
import {
  ConvocatoriaInterface,
  PaginatedConvocatoriaInterface,
} from "../../interfaces/ConvocatoriaInterface";
import { useReactQuery } from "stj-components";

const { useDelete, useFetch, usePost, useUpdate } = useReactQuery;

export const useGetConvocatorias = (queryParams?: any) =>
  useFetch<ConvocatoriaInterface[]>(
    pathToUrl(apiRoutes.convocatorias, {}, queryParams)
  );

export const useGetPaginatedConvocatorias = (queryParams?: any) =>
  useFetch<PaginatedConvocatoriaInterface>(
    pathToUrl(apiRoutes.convocatorias, {}),
    queryParams
  );

export const useGetConvocatoria = (id: number) =>
  useFetch<ConvocatoriaInterface>(
    pathToUrl(apiRoutes.convocatoriasById, { id })
  );

export const useCreateConvocatoria = () =>
  usePost<any, any>(pathToUrl(apiRoutes.convocatorias));

export const useGetConvocatoriaById = (id: number, queryParams?: any) =>
  useFetch<ConvocatoriaInterface>(
    pathToUrl(apiRoutes.convocatoriasById, { id }),
    queryParams
  );

export const useUpdateConvocatoria = (id: number) =>
  useUpdate<any, any>(pathToUrl(apiRoutes.convocatoriasById, { id }));

export const useDeleteConvocatoria = () =>
  useDelete<ConvocatoriaInterface>(pathToUrl(apiRoutes.convocatorias));
