import React from 'react';
import { MenuItem, Box, Grid, Breadcrumbs, Link, Typography, ButtonProps, } from "@mui/material";
import { PictureAsPdf } from "@mui/icons-material";
import {
  DataGrid,
  esES,
  GridRowParams,
  GridToolbarContainerProps,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridExportMenuItemProps,
  useGridApiContext,
} from "@mui/x-data-grid";
import { SimpleCard } from "stj-components";
import { columns } from "./columnsConvocatorias";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { pathToUrl } from "../../utils/urlGenerator";
import { appRoutes } from "../../appRoutes";
import { useGetPaginatedConvocatorias } from "../../services/api/convocatorias";

const ListConvocatoriasPage = () => {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);

  const navigate = useNavigate();

  const queryOptions = useMemo(
    () => ({
      page: page + 1,
      page_size: pageSize,
    }),
    [page, pageSize]
  );


  const {
    data: convocatorias,
    isError,
    isLoading,
  } = useGetPaginatedConvocatorias(queryOptions);

  const [rowCountState, setRowCountState] = useState(convocatorias?.total || 0);

  useEffect(() => {
    convocatorias?.data.forEach((partido: any, index) => {
      partido.orden = pageSize * page + index + 1;
    });

    setRowCountState((prevRowCountState: any) =>
      convocatorias?.total !== undefined ? convocatorias?.total : prevRowCountState
    );
  }, [
    convocatorias?.total,
    setRowCountState,
    convocatorias?.data,
    rowCountState,
    page,
    pageSize,
  ]);

  const handleRowClick = (params: GridRowParams) => {
    const detailURL = pathToUrl(appRoutes.detalleConvocatoria, {
      id: params.row.id,
    });

    navigate(detailURL);
  };

  useEffect(() => {
    if (isError) toast.error("Error cargando convocatorias");
  }, [isError]);

  return (
    <>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" component={RouterLink} to="/">
              Inicio
            </Link>
            <Typography color="textPrimary">Convocatorias</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <SimpleCard title="Gestión de convocatorias">
        <Box sx={{ width: "100%", mt: 2 }}>
          <DataGrid
            sx={{
              "& .MuiDataGrid-cell:hover": {
                cursor: "pointer",
              },
            }}
            componentsProps={{
              filterPanel: {
                filterFormProps: {
                  operatorInputProps: {
                    disabled: true, // If you only want to disable the operator
                    sx: { display: "none" } // If you want to remove it completely
                  },
                }
              }
            }}
            onStateChange={(e) => setRowCountState(e.pagination.rowCount)}
            autoHeight
            error={isError || undefined}
            loading={isLoading}
            getRowId={(row: any) => row.id}
            columns={columns}
            rows={convocatorias ? convocatorias.data : []}
            rowCount={rowCountState}
            disableDensitySelector
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            onRowClick={handleRowClick}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onPageChange={(newPage) => setPage(newPage)}
            rowsPerPageOptions={[10, 20, 30]}
            page={page}
            pagination
            paginationMode="server"
            density="compact"
          />
        </Box>
      </SimpleCard>
    </>
  );
};

export default ListConvocatoriasPage;
