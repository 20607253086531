import { pathToUrl } from "../../utils/urlGenerator";
import {
  PostulanteInterface,
  PaginatedPostulantesInterface,
} from "../../interfaces/PostulanteInterface";
import { apiRoutes } from "./apiRoutes";
import { useReactQuery } from "stj-components";

const { useDelete, useFetch, useFormDataPost } = useReactQuery;

export const useGetPostulantes = (queryParams?: any) =>
  useFetch<PostulanteInterface[]>(
    pathToUrl(apiRoutes.postulantes),
    queryParams
  );

export const useGetPaginatedPostulantes = (queryParams?: any) =>
  useFetch<PaginatedPostulantesInterface>(
    pathToUrl(apiRoutes.postulantes, {}),
    queryParams
  );

// export const useGetPostulante = (id: number) =>
//   useFetch<PostulanteInterface>(pathToUrl(apiRoutes.postulanteById, { id }));

// export const useCreatePostulante = () =>
//   usePost<PostulanteInterface, PostulanteInterface>(
//     pathToUrl(apiRoutes.postulantes)
//   );

// export const useCreatePostulanteManual = () =>
//   usePost<PostulanteAddEditInterface, PostulanteAddEditInterface>(
//     pathToUrl(apiRoutes.postulantesManual)
//   );

export const useUpdatePostulante = (id: number) =>
  useFormDataPost<any, any>(pathToUrl(apiRoutes.postulantesById, { id }));

export const useDeletePostulante = () =>
  useDelete<PostulanteInterface>(pathToUrl(apiRoutes.postulantes));

// export const useImportPostulantes = () =>
//   useFormDataPost<Postulantes, any>(pathToUrl(apiRoutes.postulanteImport));

// export const useGetCountPostulantesByLocalidad = (id: number) =>
//   useFetch<PostulanteCantidadPorLocalidadInterface[]>(
//     pathToUrl(apiRoutes.CountPostulantesByLocalidad, { id })
//   );
