import { useEffect, useMemo, useState } from "react";
import {
  useGetPaginatedPostulantes,
  useUpdatePostulante,
} from "../../../services/api/postulantes";
import GenericCrud from "../../../components/crud/GenericCrud";
import { Alert, AlertTitle, CircularProgress, Grid } from "@mui/material";
import { columnsPostulantesSinConfirmar } from "./columnsPostulantesSinConfirmar";
import { GridSortModel } from "@mui/x-data-grid";
import {
  formInitialValuesPostulanteSinConfirmar,
  FormPostulanteSinConfirmar,
  UpdateValidationSchemaPostulanteSinConfirmar
} from "./forms/FormPostulanteSinConfirmar";
import { PostulanteInterface } from "@interfaces/PostulanteInterface";
import { ReenviarEmailConfirmacionModal } from "./forms/ReenviarEmailConfirmacionModal";
import { ConfirmarEmailModal } from "./forms/ConfirmarEmailModal";
import SearchPostulante from "./SearchPostulante";


interface ListPostulantesSinConfirmarProps {
  convocatoriaId: string;
}

const ListPostulantesSinConfirmar = (
  props: ListPostulantesSinConfirmarProps
) => {
  const { convocatoriaId } = props;

  const [queryParams, setQueryParams] = useState({});
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sort, setSort] = useState<string>("");

  const [postulanteSelected, setPostulanteSelected] = useState<PostulanteInterface>();
  const [openReenviarEmailConfirmacionModal, setOpenReenviarEmailConfirmacionModal] = useState<boolean>(false);
  const [openConfirmarEmailModal, setOpenConfirmarEmailModal] = useState<boolean>(false);

  const queryOptions = useMemo(
    () => ({
      page: page + 1,
      page_size: pageSize,
      sort: sort,
      include: "genero,perfiles",
      "filter[convocatoria_id]": convocatoriaId,
      "filter[inscripcionNoConfirmada]": "true",
      ...queryParams,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, pageSize, sort, queryParams]
  );

  const handleSort = (sortModel: GridSortModel) => {
    if (sortModel && sortModel.length === 1) {
      const sortDirection: string = sortModel[0].sort === "desc" ? "-" : "";
      const sorteableAttr: string =
        columnsPostulantesSinConfirmar.filter(
          (col) => col.field === sortModel[0].field
        )[0].sorteableColumnFilter ?? "";
      const querySort: string = `${sortDirection}${sorteableAttr}`;
      setSort(querySort);
    }
  };

  const {
    data: postulantes,
    isLoading: isLoadingPostulantes,
    isError: isErrorPostulantes,
    refetch: refetchPostulantes,
  } = useGetPaginatedPostulantes(queryOptions);

  const handleReenviarEmailConfirmacionModalToggle = (postulante: PostulanteInterface) => {
    // console.log({ accion: 'handleReenviarEmailConfirmacionModalToggle', postulante });
    setPostulanteSelected(postulante);
    setOpenReenviarEmailConfirmacionModal(true);
  }

  const handleConfirmarEmailModalToggle = (postulante: PostulanteInterface) => {
    // console.log({ accion: 'handleConfirmarEmailModalToggle', postulante });
    setPostulanteSelected(postulante);
    setOpenConfirmarEmailModal(true);
  }

  const [rowCountState, setRowCountState] = useState(postulantes?.total || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState: any) =>
      postulantes?.total !== undefined ? postulantes?.total : prevRowCountState
    );
  }, [postulantes?.total, setRowCountState]);

  const handleOnSubmitFilter = (data: any) => {
    const { nombre, apellido, documento, email, perfilIds } = data;

    const filters = {
      "filter[nombre]": nombre,
      "filter[apellido]": apellido,
      "filter[documento]": documento,
      "filter[email]": email,
      "filter[perfil]": perfilIds?.join(",") ?? "",
    };

    setQueryParams(filters);
  };

  return (
    <>
      <Grid item xs={12} mb={2}>
        <Alert severity="info">
          <AlertTitle>
            En esta pestaña se muestran los postulantes que todavía no han
            confirmado su correo electrónico.
          </AlertTitle>
        </Alert>
      </Grid>
      <Grid container item xs={12} marginBottom={2}>
        <SearchPostulante handleOnSubmit={handleOnSubmitFilter} />
      </Grid>

      {postulantes && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <GenericCrud
              data={postulantes.data.map((row) => {
                return {
                  ...row,
                  handleReenviarEmailConfirmacionModalToggle,
                  handleConfirmarEmailModalToggle,
                };
              })}
              columns={columnsPostulantesSinConfirmar}
              viewOnClick={true}
              editOnClick={false}
              form={<FormPostulanteSinConfirmar />}
              formValidation={UpdateValidationSchemaPostulanteSinConfirmar}
              formInitialValues={formInitialValuesPostulanteSinConfirmar}
              hideAddBtn={true}
              addMutation={() => {
                console.log("No se pueden crear postulantes..");
              }}
              useEditMutation={useUpdatePostulante}
              deleteMutation={() => {
                console.log("No se pueden eliminar postulantes..");
              }}
              refetch={refetchPostulantes}
              page={page}
              pageSize={pageSize}
              rowCount={rowCountState}
              setPage={setPage}
              setPageSize={setPageSize}
              handleSort={handleSort}
            />
          </Grid>
        </Grid>
      )}

      {isErrorPostulantes && <div>Error cargando</div>}

      {isLoadingPostulantes && <CircularProgress />}


      {/* Modales de accion */}
      {openReenviarEmailConfirmacionModal && postulanteSelected && postulanteSelected.id && (
        <ReenviarEmailConfirmacionModal
          postulante={postulanteSelected}
          open={openReenviarEmailConfirmacionModal}
          setOpen={setOpenReenviarEmailConfirmacionModal}
          refetch={refetchPostulantes}
        />
      )}

      {openConfirmarEmailModal && postulanteSelected && postulanteSelected.id && (
        <ConfirmarEmailModal
          postulante={postulanteSelected}
          open={openConfirmarEmailModal}
          setOpen={setOpenConfirmarEmailModal}
          refetch={refetchPostulantes}
        />
      )}
    </>
  );
};

export default ListPostulantesSinConfirmar;
