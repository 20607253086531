import { useState } from "react";
import { Form, Formik } from "formik";
import { createAxios, Modal, TextInput } from "stj-components";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import * as Yup from "yup";
import { Grid, IconButton, InputAdornment, Button } from "@mui/material";
import toast from "react-hot-toast";

interface ResetPasswordProps {
  show: boolean;
  handleClose: () => void;
}

const ModalResetPassword = (props: ResetPasswordProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const axiosInstance = createAxios;

  const { show, handleClose } = props;

  const handleSetPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Modal
      title="Cambiar contraseña"
      open={show}
      handleClose={handleClose}
      maxWidth="sm"
    >
      {/* Form to reset password */}
      <Formik
        initialValues={{
          password: "",
          password_confirmation: "",
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .required("El campo es obligatorio")
            .min(8, "La contraseña debe tener al menos 8 caracteres")
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
              "La contraseña debe tener al menos una letra mayúscula, una minúscula y un número"
            ),
          password_confirmation: Yup.string()
            .required("El campo es obligatorio")
            .oneOf([Yup.ref("password"), null], "Las contraseñas no coinciden"),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const { data } = await axiosInstance.post(
              "/users/reset-password",
              values
            );

            // Show success message
            toast.success(data.message);

            // close modal
            handleClose();
          } catch (error: any) {
            toast.error(error?.response?.data?.error);
            toast.error(error?.response?.data?.message);
          }
        }}
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <TextInput
                  autoComplete="new-password"
                  name="password"
                  label="Contraseña"
                  type={showPassword ? "text" : "password"}
                  placeholder="Escriba su contraseña"
                  fullWidth
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleSetPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  autoComplete="new-password"
                  name="password_confirmation"
                  label="Repetir nueva contraseña"
                  type={showPassword ? "text" : "password"}
                  placeholder="Escriba su nuevacontraseña"
                  fullWidth
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleSetPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item sm={6}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={!isValid || isSubmitting}
                >
                  {isSubmitting ? "Guardando.." : "Cambiar contraseña"}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ModalResetPassword;
