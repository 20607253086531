import React, { useEffect, useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  CircularProgress,
  Grid,
} from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import { SimpleCard } from "stj-components";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  convocatoriaValidationSchema,
  convocatoriaInitialValuesInterface,
} from "./Forms/convocatoriaValidationSchema";
import ConvocatoriaForm from "./Forms/ConvocatoriaForm";
import StyledForm from "./Forms/StyledForm";
import { useCreateConvocatoria } from "../../services/api/convocatorias";
import { appRoutes } from "src/appRoutes";

const steps = ["Convocatoria"];
const CONVOCATORIA_FORM = 0;

const convocatoriaFormInitialValues: convocatoriaInitialValuesInterface = {
  descripcion: "",
  expediente: "",
  observacion: "",
  fecha_inicio_comunicacion_publica: "",
  fecha_fin_comunicacion_publica: "",
  fecha_inicio_inscripcion: "",
  fecha_fin_inscripcion: "",
  fecha_inicio_capacitacion: "",
  fecha_fin_capacitacion: "",
  fecha_inicio_examen: "",
  fecha_fin_examen: "",
  fecha_inicio_gestion_curriculum: "",
  fecha_fin_gestion_curriculum: "",
};

const CreateConvocatoriaPage = () => {
  const { mutateAsync: addConvocatoria } = useCreateConvocatoria();
  const [activeStep, setActiveStep] = useState(CONVOCATORIA_FORM);
  const navigate = useNavigate();

  const currentValidationSchema = convocatoriaValidationSchema[activeStep];

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <ConvocatoriaForm />
        );
      default:
        return <div>Not Found</div>;
    }
  };

  const resetForm = (
    actions: FormikHelpers<convocatoriaInitialValuesInterface>
  ) => {
    actions.setTouched({});
    actions.setSubmitting(false);
  };

  const submitForm = async (
    values: convocatoriaInitialValuesInterface,
    actions: FormikHelpers<convocatoriaInitialValuesInterface>
  ) => {
    try {
      console.log("Guardando convocatoria");
      console.log(values);

      const formValues = {
        descripcion: values.descripcion,
        expediente: values.expediente,
        observacion: values.observacion,
        fecha_inicio_comunicacion_publica: values.fecha_inicio_comunicacion_publica,
        fecha_fin_comunicacion_publica: values.fecha_fin_comunicacion_publica,
        fecha_inicio_inscripcion: values.fecha_inicio_inscripcion,
        fecha_fin_inscripcion: values.fecha_fin_inscripcion,
        fecha_inicio_capacitacion: values.fecha_inicio_capacitacion,
        fecha_fin_capacitacion: values.fecha_fin_capacitacion,
        fecha_inicio_examen: values.fecha_inicio_examen,
        fecha_fin_examen: values.fecha_fin_examen,
        fecha_inicio_gestion_curriculum: values.fecha_inicio_gestion_curriculum,
        fecha_fin_gestion_curriculum: values.fecha_fin_gestion_curriculum,
      };

      addConvocatoria(formValues, {
        onSuccess(data) {
          toast.success(`La convocatoria se registró correctamente`);
          console.log({ response: data });
          navigate(appRoutes.listarConvocatorias);
        },
      });
    } catch (error: any) {
      toast.error(`Algo salió mal al guardar la convocatoria: ${error}`);
    } finally {
      resetForm(actions);
    }
  };

  const handleSubmit = (
    values: convocatoriaInitialValuesInterface,
    actions: FormikHelpers<convocatoriaInitialValuesInterface>
  ) => {
    submitForm(values, actions);
    resetForm(actions);
  };

  return (
    <SimpleCard title="Nueva Convocatoria">
      <React.Fragment>
        <Formik
          initialValues={convocatoriaFormInitialValues}
          validationSchema={currentValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, handleSubmit }) => (
            <StyledForm>
              {renderStepContent(activeStep)}

              <Grid container justifyContent={"flex-end"} sx={{ mt: 2 }}>
                <Grid item>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {"Crear Convocatoria"}
                  </Button>
                  {isSubmitting && <CircularProgress size={24} />}
                </Grid>
              </Grid>
            </StyledForm>
          )}
        </Formik>
      </React.Fragment>
    </SimpleCard>
  );
};

export default CreateConvocatoriaPage;
