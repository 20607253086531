import { useGetPerms, useGetRoles } from "./auth/hooks";
import { useEffect, useState } from "react";
import { Layout as STJLayout, useAuth } from "stj-components";
import { optionsList } from "./ListItems";

const checkOptionPerms = (option: any): Boolean | Array<any> => {
  return true;
};

interface LayoutProps {
  openResetPasswordModal: Function;
}

export const Layout = (props: LayoutProps) => {
  const { openResetPasswordModal } = props;
  const { logout } = useAuth();
  const { roles } = useGetRoles();
  const { perms } = useGetPerms();
  const [sideBarOptions, setSideBarOptions] = useState<Array<any>>(optionsList);

  const updateSideBarOptionsList = () => {
    // console.log("Actualizando links");
    // console.log({ roles });
    // console.log({ perms });

    const opciones = optionsList.filter((option) => {
      // Reviso si la opción tiene restricciones por roles o permisos
      if (option.rolesRequired && option.rolesRequired.length > 0) {
        const hasRolRequired = option.rolesRequired.filter((rol) =>
          roles.includes(rol)
        );
        if (hasRolRequired.length === 0) return false;
      }

      if (option.permsRequired && option.permsRequired.length > 0) {
        const hasPermRequired = option.permsRequired.filter((perm) =>
          perms.includes(perm)
        );
        if (hasPermRequired.length === 0) return false;
      }

      // Reviso los roles y permisos requeridos para los hijos
      if (option.options && option.options.length > 0) {
        const childOptions = option.options.filter((option) => {
          if (option.rolesRequired && option.rolesRequired.length > 0) {
            const hasRolRequired = option.rolesRequired.filter((rol) =>
              roles.includes(rol)
            );
            if (hasRolRequired.length === 0) return false;
          }

          if (option.permsRequired && option.permsRequired.length > 0) {
            const hasPermRequired = option.permsRequired.filter((perm) =>
              perms.includes(perm)
            );
            if (hasPermRequired.length === 0) return false;
          }

          return true;
        });

        if (childOptions && childOptions.length === 0) return false;
        // Con esto dejo sólo los hijos filtrados
        option.options = childOptions;
      }

      return true;
    });

    // console.log({ opciones });
    setSideBarOptions(opciones);
  };

  useEffect(() => {
    updateSideBarOptionsList();
  }, []);

  // useEffect(() => {
  //   console.log({ roles });
  // }, [roles]);

  // useEffect(() => {
  //   console.log({ perms });
  // }, [perms]);

  return true ? (
    <STJLayout
      title={
        process.env.REACT_APP_MODE === "dev"
          ? "HAIN - DESARROLLO"
          : "HAIN"
      }
      subTitle={""}
      appBarItems={[
        {
          text: "Cambiar contraseña",
          onClick: () => openResetPasswordModal(),
        },
        {
          text: "Cerrar Sesión",
          onClick: () => logout(),
        },
      ]}
      sidebarItems={sideBarOptions}
      badgeList={{}}
    />
  ) : null;
};
