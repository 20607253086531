const basePathConvocatorias = "/convocatorias";
const basePathPostulante = "/postulantes";

export const appRoutes = {
  /** Rutas convocatorias */
  crearConvocatorias: `${basePathConvocatorias}/crear`,
  listarConvocatorias: `${basePathConvocatorias}/listar`,
  detalleConvocatoria: `${basePathConvocatorias}/detalleConvocatoria/:id`,

  /** Rutas postulantes */
  listaPostulante: `${basePathPostulante}/listar`,
};
