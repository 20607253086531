import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// Tema customizado para la app
const theme = createTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_MODE === "dev" ? "#BC544B" : "#1363DF",
    },
    secondary: {
      main: "#06283D",
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;
