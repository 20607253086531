import { useState } from "react";
import { SimpleCard, SimpleTabs } from "stj-components";
import ListPostulantesSinConfirmar from "./ListPostulantes/ListPostulantesSinConfirmar";
import ListPostulantesConfirmados from "./ListPostulantes/ListPostulantesConfirmados";
import { useGetConvocatorias } from "../../services/api/convocatorias";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

const PostulantesPage = () => {
  const [convocatoriaId, setConvocatoriaId] = useState("");

  const { data: convocatorias } = useGetConvocatorias({});

  const handleChange = (event: SelectChangeEvent) => {
    setConvocatoriaId(event.target.value as string);
  };

  return (
    <SimpleCard title="Gestión de Postulantes">
      <Grid container spacing={1}>
        {/* Comienza select */}
        <Grid item xs={12}>
          <FormControl fullWidth sx={{ mb: 1 }}>
            <InputLabel id="select-eleccion">Convocatoria</InputLabel>
            <Select
              labelId="select-eleccion"
              id="select-eleccion"
              value={convocatoriaId}
              label="Convocatoria"
              onChange={handleChange}
            >
              {convocatorias?.map((convocatoria, idx) => (
                <MenuItem key={idx} value={String(convocatoria.id)}>
                  {convocatoria.descripcion}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {/* Fin select */}

        {convocatoriaId !== "" && (
          <>
            <Grid item xs={12}>
              <SimpleTabs
                tabs={[
                  { label: "Postulantes sin confirmar" },
                  { label: "Postulantes confirmados" },
                  // { label: "Notificado Capacitación" },
                  // { label: "Notificado & Confirmado" },
                ]}
                panels={[
                  <ListPostulantesSinConfirmar convocatoriaId={convocatoriaId} />,
                  <ListPostulantesConfirmados convocatoriaId={convocatoriaId} />,
                  // <ListNotificados convocatoriaId={convocatoriaId} />,
                  // <ListNotificadosConfirmados convocatoriaId={convocatoriaId} />,
                ]}
              />
            </Grid>
          </>
        )}
      </Grid>
    </SimpleCard>
  );
};

export default PostulantesPage;
