import { Grid, TextField } from "@mui/material";
import { ChipRow } from "stj-components";
import { ConvocatoriaInterface } from "../../interfaces/ConvocatoriaInterface";

interface FieldsEleccionProps {
  convocatoria: ConvocatoriaInterface;
  refresh?: any;
}

const DataConvocatoria = (props: FieldsEleccionProps) => {
  const { convocatoria, refresh } = props;

  return (
    <Grid container item spacing={2} sx={{ pl: 3, pr: 3 }}>
      <Grid item xs={12}>
        <TextField
          id="descripcion"
          label="Descripción"
          value={convocatoria.descripcion}
          variant="filled"
          fullWidth
          size="small"
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          id="expediente"
          label="Expediente"
          value={convocatoria.expediente ?? ""}
          variant="filled"
          fullWidth
          size="small"
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          id="observaciones"
          label="Observaciones"
          value={convocatoria.observacion ?? ""}
          variant="filled"
          fullWidth
          size="small"
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default DataConvocatoria;
