import { pathToUrl } from "../../utils/urlGenerator";
import {
  ConvocatoriaCalendarioInterface,
  PaginatedConvocatoriaCalendarioInterface,
} from "../../interfaces/ConvocatoriaCalendarioInterface";
import { apiRoutes } from "./apiRoutes";
import { useReactQuery } from "stj-components";

const { useDelete, useFetch, usePost, useUpdate } = useReactQuery;

export const useGetConvocatoriasCalendarios = (queryParams?: any) =>
  useFetch<ConvocatoriaCalendarioInterface[]>(
    pathToUrl(apiRoutes.convocatoriaCalendarios),
    queryParams
  );

export const useGetPaginatedConvocatoriasCalendarios = (queryParams?: any) =>
  useFetch<PaginatedConvocatoriaCalendarioInterface>(
    pathToUrl(apiRoutes.convocatoriaCalendarios, {}),
    queryParams
  );

export const useGetConvocatoriaCalendario = (id: number) =>
  useFetch<ConvocatoriaCalendarioInterface>(
    pathToUrl(apiRoutes.convocatoriaCalendariosById, { id })
  );

export const useCreateConvocatoriaCalendario = () =>
  usePost<ConvocatoriaCalendarioInterface, ConvocatoriaCalendarioInterface>(
    pathToUrl(apiRoutes.convocatoriaCalendarios)
  );

export const useUpdateConvocatoriaCalendario = (id: number) =>
  useUpdate<any, any>(pathToUrl(apiRoutes.convocatoriaCalendariosById, { id }));

export const useDeleteConvocatoriaCalendario = () =>
  useDelete<ConvocatoriaCalendarioInterface>(
    pathToUrl(apiRoutes.convocatoriaCalendarios)
  );
