import { Collapse, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth } from "stj-components";

const HomePage = () => {
  const { user } = useAuth();

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(true);
  }, []);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      spacing={2}
    >
      <Grid
        item
        container
        display="flex"
        direction="column"
        justifyContent="center"
        textAlign="center"
        sx={{ height: "50vh" }}
      >
        <Collapse
          in={checked}
          {...(checked ? { timeout: 1000 } : {})}
          collapsedSize={50}
        >
          <Typography
            variant={"h3"}
            component={"h2"}
            align="center"
            sx={{ color: "black", fontSize: "4rem" }}
          >
            <b>HAIN</b>
          </Typography>

          <Typography
            variant={"h3"}
            component={"h2"}
            align="center"
            sx={{ color: "black", fontSize: "3rem" }}
          >
            Gestión de postulantes
          </Typography>
          <br></br>

          <Typography variant="h4" textAlign="center">
            Bienvenido {user?.name}
          </Typography>
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default HomePage;
