import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import ErrorIcon from "@mui/icons-material/Error";

type ExportMenuProps = {
  reactQueryHook: Function;
  queryOptions: any;
  columns: any;
  fileName: string;
  label?: string;
};

export const ExportMenu = (props: ExportMenuProps) => {
  const { reactQueryHook, queryOptions, columns, fileName, label } = props;
  const [newData, setNewData] = useState();

  const newQueryOptions = { ...queryOptions };
  delete newQueryOptions.page;
  delete newQueryOptions.page_size;

  const { data, isError, isSuccess, isLoading, isFetching } =
    reactQueryHook(newQueryOptions);

  const formatRow = (row: any) => {
    let newRow = {};
    columns.forEach((column: any) => {
      if (column.field === "actions") return;

      let value: string;
      if (column.valueGetter) {
        value = column.valueGetter({ row: row })
          ? column.valueGetter({ row: row })
          : "";
      } else {
        value = row[column.field] ? row[column.field] : "";
      }


      if (column.field === "renderIndex") {
        value = row.index + 1;
      }


      if (
        value === null ||
        value === "null" ||
        value === undefined ||
        value === "undefined"
      )
        value = "";

      let attribute: string;
      if (column.headerName) {
        attribute = column.headerName;
      } else {
        attribute = column.field;
      }

      newRow = { ...newRow, [attribute]: value };
    });
    return newRow;
  };

  const formatData = () => {
    const newData = data.map((row: any, index: number) => formatRow({ ...row, index }));
    // console.log({ newData });
    setNewData(newData);
  };

  useEffect(() => {
    if (isSuccess && data) {
      formatData();
    }
  }, [isSuccess, data]);

  return (
    <>
      {(isLoading || isFetching) && (
        <Tooltip title={label ?? "Descargar CSV"}>
          <IconButton aria-label={label ?? "Descargar CSV"}>
            <CircularProgress size={15} />
          </IconButton>
        </Tooltip>
      )}
      {isError && (
        <Tooltip title="Descargar CSV - ERROR">
          <IconButton aria-label="Descargar CSV - ERROR">
            <ErrorIcon color="error" />
          </IconButton>
        </Tooltip>
      )}
      {!isLoading && !isFetching && isSuccess && newData && (
        // <Tooltip title="Descargar CSV">        
        // <IconButton aria-label="Descargar CSV">     
        <CSVLink data={newData} filename={fileName}>
          <Grid container item xs={12} justifyContent={"right"}>
            {label ?? "Descargar CSV"} &nbsp;
            <DownloadForOfflineIcon color="secondary" />
          </Grid>
        </CSVLink>
        // </IconButton>
        // </Tooltip>
      )}
    </>
  );
};