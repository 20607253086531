import { compile } from "path-to-regexp";

export const pathToUrl = (
  path: string,
  params: object = {},
  queryString: any = {}
) => {
  let queryParams = "";
  let URL = "";
  const emptyQueryString = Object.keys(queryString).length === 0;

  const toPath = compile(path);
  const pathUrl = toPath(params);

  if (!emptyQueryString) {
    queryParams = new URLSearchParams(queryString).toString();
    URL = `${pathUrl}?${queryParams}`;
  } else {
    URL = `${pathUrl}`;
  }

  return URL;
};
