import { useFormikContext } from "formik";
import { convocatoriaInitialValuesInterface } from "./convocatoriaValidationSchema";
import {
  Divider,
  Grid,
} from "@mui/material";
import {
  TextInput,
} from "stj-components";


const ConvocatoriaForm = () => {
  const { values, setFieldValue } =
    useFormikContext<convocatoriaInitialValuesInterface>();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{ mr: 20 }}
    >
      <Grid item xs={12}>
        <TextInput
          label="Nombre de la convocatoria"
          name="descripcion"
          placeholder="Ingrese Descripción de la Convocatoria"
          type="string"
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <TextInput
          label="Expediente"
          name="expediente"
          type="text"
          placeholder="Ingrese expediente de la convocatoria"
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <TextInput
          label="Observaciones"
          name="observacion"
          type="text"
          placeholder="Ingrese observaciones"
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        <Divider variant="middle" sx={{ mb: 1 }} />
      </Grid>

      <Grid item xs={6}>
        <TextInput
          label="Inicio de comunicado público"
          name="fecha_inicio_comunicacion_publica"
          type="date"
          placeholder="Inicio de comunicado público"
          fullWidth
          required
        />
      </Grid>

      <Grid item xs={6}>
        <TextInput
          label="Fin de comunicado público"
          name="fecha_fin_comunicacion_publica"
          type="date"
          placeholder="Fin de comunicado público"
          fullWidth
          required
        />
      </Grid>

      <Grid item xs={6}>
        <TextInput
          label="Inicio de inscripciones"
          name="fecha_inicio_inscripcion"
          type="date"
          placeholder="Inicio de inscripciones"
          fullWidth
          required
        />
      </Grid>

      <Grid item xs={6}>
        <TextInput
          label="Fin de inscripciones"
          name="fecha_fin_inscripcion"
          type="date"
          placeholder="Fin de inscripciones"
          fullWidth
          required
        />
      </Grid>

      <Grid item xs={6}>
        <TextInput
          label="Inicio de capacitaciones"
          name="fecha_inicio_capacitacion"
          type="date"
          placeholder="Inicio de capacitaciones"
          fullWidth
          required
        />
      </Grid>


      <Grid item xs={6}>
        <TextInput
          label="Fin de capacitaciones"
          name="fecha_fin_capacitacion"
          type="date"
          placeholder="Fin de capacitaciones"
          fullWidth
          required
        />
      </Grid>

      <Grid item xs={6}>
        <TextInput
          label="Inicio de examenes"
          name="fecha_inicio_examen"
          type="date"
          placeholder="Inicio de examenes"
          fullWidth
          required
        />
      </Grid>

      <Grid item xs={6}>
        <TextInput
          label="Fin de examenes"
          name="fecha_fin_examen"
          type="date"
          placeholder="Fin de examenes"
          fullWidth
          required
        />
      </Grid>

      <Grid item xs={6}>
        <TextInput
          label="Inicio de gestión de curriculums"
          name="fecha_inicio_gestion_curriculum"
          type="date"
          placeholder="Inicio de gestión de curriculums"
          fullWidth
          required
        />
      </Grid>

      <Grid item xs={6}>
        <TextInput
          label="Fin de gestión de curriculums"
          name="fecha_fin_gestion_curriculum"
          type="date"
          placeholder="Fin de gestión de curriculums"
          fullWidth
          required
        />
      </Grid>

    </Grid >
  );
};

export default ConvocatoriaForm;
