import * as Yup from "yup";
import { string } from "yup/lib/locale";

export interface convocatoriaInitialValuesInterface {
  /** DATOS CONVOCATORIA */
  descripcion: string;
  expediente: string;
  observacion: string;
  fecha_inicio_comunicacion_publica: Date | string;
  fecha_fin_comunicacion_publica: Date | string;
  fecha_inicio_inscripcion: Date | string;
  fecha_fin_inscripcion: Date | string;
  fecha_inicio_capacitacion: Date | string;
  fecha_fin_capacitacion: Date | string;
  fecha_inicio_examen: Date | string;
  fecha_fin_examen: Date | string;
  fecha_inicio_gestion_curriculum: Date | string;
  fecha_fin_gestion_curriculum: Date | string;
}

export const convocatoriaValidationSchema = [
  /** VALIDACION DATOS CONVOCATORIA */
  Yup.object({
    descripcion: Yup.string().required(
      "Se necesita una descripcion de la convocatoria"
    ),
    expediente: Yup.string().nullable(),
    observacion: Yup.string().nullable(),
    fecha_inicio_comunicacion_publica: Yup.date().min(
      new Date(),
      "La fecha debe ser mayor a hoy"
    ),
    fecha_fin_comunicacion_publica: Yup.date().min(
      new Date(),
      "La fecha debe ser mayor a hoy"
    ),
    fecha_inicio_inscripcion: Yup.date().min(
      new Date(),
      "La fecha debe ser mayor a hoy"
    ),
    fecha_fin_inscripcion: Yup.date().min(
      new Date(),
      "La fecha debe ser mayor a hoy"
    ),
    fecha_inicio_capacitacion: Yup.date().min(
      new Date(),
      "La fecha debe ser mayor a hoy"
    ),
    fecha_fin_capacitacion: Yup.date().min(
      new Date(),
      "La fecha debe ser mayor a hoy"
    ),
    fecha_inicio_examen: Yup.date().min(
      new Date(),
      "La fecha debe ser mayor a hoy"
    ),
    fecha_fin_examen: Yup.date().min(
      new Date(),
      "La fecha debe ser mayor a hoy"
    ),
    fecha_inicio_gestion_curriculum: Yup.date().min(
      new Date(),
      "La fecha debe ser mayor a hoy"
    ),
    fecha_fin_gestion_curriculum: Yup.date().min(
      new Date(),
      "La fecha debe ser mayor a hoy"
    ),
  }),
];
