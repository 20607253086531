import React from "react";
import { GridColDef, GridRenderCellParams, GridValidRowModel } from "@mui/x-data-grid";
import { PostulanteInterface } from "../../../interfaces/PostulanteInterface";
import { formatDateAR } from "../../../utils/utils";
import { MenuOption } from "@components/crud/GridMenuOption";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from '@mui/icons-material/Send';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { ContextualButton } from "stj-components";

interface Sorteable {
  sorteableColumnFilter?: string;
}

interface SorteableGridColDef<T extends GridValidRowModel> extends GridColDef<T>, Sorteable { };

export const columnsPostulantesSinConfirmar: SorteableGridColDef<PostulanteInterface>[] = [
  {
    field: 'actions',
    headerName: ' ',
    sortable: false,
    filterable: false,
    width: 50,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params: GridRenderCellParams<boolean>) => {
      const options: MenuOption[] = [
        {
          children: (
            <>
              <OpenInNewIcon />
              Ver
            </>
          ),
          action: () => {
            params.row.handleViewModalToggle(params.row);
          }
        },
        {
          children: (
            <>
              <EditIcon />
              Editar
            </>)
          ,
          action: () => {
            params.row.handleEditModalToggle(params.row);
          }
        },
        {
          children: (
            <>
              <SendIcon />
              Reenviar email de confirmación
            </>)
          ,
          action: () => {
            params.row.handleReenviarEmailConfirmacionModalToggle(params.row);
          }
        },
        {
          children: (
            <>
              <MarkEmailReadIcon />
              Confirmar email de postulante
            </>)
          ,
          action: () => {
            params.row.handleConfirmarEmailModalToggle(params.row);
          }
        },
      ];

      return <ContextualButton options={options}></ContextualButton>;
    }
  },
  {
    field: "documento",
    headerName: "DNI",
    sortable: true,
    sorteableColumnFilter: "documento",
    width: 100,
  },
  {
    field: "apellido",
    headerName: "Apellido",
    sortable: true,
    sorteableColumnFilter: "apellido",
    width: 200,
  },
  {
    field: "nombre",
    headerName: "Nombre",
    sortable: true,
    sorteableColumnFilter: "nombre",
    width: 200,
  },
  {
    field: "email",
    headerName: "Email",
    sortable: true,
    sorteableColumnFilter: "email",
    width: 280,
    valueGetter: (params) => `${params.row?.email}`,
  },
  {
    field: "telefono",
    headerName: "Telefono",
    sortable: false,
    width: 120,
    valueGetter: (params) => `${params.row?.telefono}`,
  },
  {
    field: "localidad",
    headerName: "Localidad",
    sortable: true,
    sorteableColumnFilter: "localidad",
    width: 100,
  },
  {
    field: "domicilio",
    headerName: "Dirección",
    sortable: true,
    sorteableColumnFilter: "domicilio",
    width: 280,
  },
  {
    field: "fecha_nacimiento",
    headerName: "Fecha Nacimiento",
    sortable: false,
    width: 150,
    renderCell: (params) => {
      return formatDateAR(params.row.fecha_nacimiento);
    }
  },
  {
    field: "perfiles",
    headerName: "Perfiles",
    sortable: false,
    width: 150,
    renderCell: (params) => {
      return (
        <>
          {params.row.perfiles?.map((perfil: any) => (
            <React.Fragment key={perfil.id}>
              {perfil.label}
              <br />
            </React.Fragment>
          ))}
        </>
      );
    },
  },
];
