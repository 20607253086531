import React from "react";
import { Formik, Form } from "formik";
import { Dialog, Grid } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import {
  FormButton,
  SimpleBackdrop,
  ModalForm,
  SimpleCard
} from "stj-components";
import { OptionalObjectSchema } from "yup/lib/object";

interface CrudAddEditModalProps {
  title: string;
  open: boolean;
  edit: boolean;
  handleMutation: (data: any) => void;
  handleClose: () => void;
  form: React.ReactNode;
  formValidation: OptionalObjectSchema<any>;
  formInitialValues: any;
}

const CrudAddEditModal = (props: CrudAddEditModalProps) => {
  const {
    title,
    open,
    edit,
    form,
    formValidation,
    formInitialValues,
    handleMutation,
    handleClose
  } = props;

  return (
    <Dialog
      open={open}
    >
      <SimpleCard>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidation}
          onSubmit={(values, { setSubmitting }) => {
            handleMutation(values);
            handleClose();
            setSubmitting(false);
          }}
        >
          {props =>
            <React.Fragment>
              <SimpleBackdrop open={props.isSubmitting} text="Guardando" />
              <ModalForm title={title} open={open} handleClose={handleClose} maxWidth="lg" fullWidth>
                <br />
                <Form>
                  {form}
                  {edit &&
                    <Grid item xs={12}>
                      <FormButton
                        text={"Guardar"}
                        startIcon={<SaveIcon />}
                        variant={"contained"}
                        type="submit"
                      />
                    </Grid>
                  }
                </Form>
              </ModalForm>
            </React.Fragment>}
        </Formik>
      </SimpleCard>
    </Dialog>
  );
};

export default CrudAddEditModal;
