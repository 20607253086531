import * as Yup from 'yup'

export const validationSchema = Yup.object({
  firstName: Yup.string()
  .required("Se requiere nombre"),
  lastName: Yup.string()
  .required("Se requiere apellido"),
  email: Yup.string()
  .email("Debe ser un email válido: ejemplo@mail.com")
  .required("Se requiere email"),
  password: Yup.string()
  .required("Se requiere contraseña")
});