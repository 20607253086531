import { Permission } from "@interfaces/Permission";
import { Role } from "@interfaces/Role";
import { UserInterface } from "@interfaces/UserInterface";
import { useAuth } from "stj-components";

export const useHasRole = (roles: string[]) => {
  const { user } = useAuth();

  if (roles.length === 0) return { hasRole: true };

  const hasRole: boolean =
    (user as UserInterface).roles?.reduce(
      (acc, curr) => (roles.includes(curr.name) ? true : acc),
      false
    ) ?? false;
  return { hasRole };
};

export const useHasPerm = (permissions: string[]) => {
  const { user } = useAuth();

  if (permissions.length === 0) return { hasPerm: true };

  const hasPerm: boolean =
    (user as UserInterface).permissions?.reduce(
      (acc, curr) => (permissions.includes(curr.name) ? true : acc),
      false
    ) ?? false;

  const hasPermInRole: boolean =
    (user as UserInterface).roles?.reduce((rAcc, rCurr) => {
      return rCurr.permissions.reduce(
        (pAcc, pCurr) => (permissions.includes(pCurr.name) ? true : pAcc),
        rAcc
      );
    }, false) ?? false;

  return { hasPerm: hasPerm || hasPermInRole };
};

export const useGetRoles = () => {
  const { user } = useAuth();
  const roles: string[] =
    (user as UserInterface).roles?.reduce(
      (acc: string[], curr: Role) => [...acc, curr.name],
      []
    ) ?? [];
  return { roles };
};

export const useGetPerms = () => {
  const { user } = useAuth();
  const perms: string[] =
    (user as UserInterface).permissions?.reduce(
      (acc: string[], curr: Permission) => [...acc, curr.name],
      []
    ) ?? [];

  const permsInRoles: string[] =
    (user as UserInterface).roles?.reduce((rAcc: string[], rCurr: Role) => {
      const permsInRole: string[] = rCurr.permissions.reduce(
        (pAcc: string[], pCurr: Permission) => [...pAcc, pCurr.name],
        []
      );
      return [...rAcc, ...permsInRole];
    }, []) ?? [];

  return { perms: [...perms, ...permsInRoles] };
};
