import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";

const NoMatch = () => {

  const navigate = useNavigate();

  return (
    <Container maxWidth="md">

      <Grid 
        container 
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        spacing={3}
      >
        
        <Grid item xs={12} md={8} lg={9}>
          <Paper sx={{ p:3 }}>
            <Typography
              align="center"
              color="textPrimary"
              variant="h2"
            >
              404: La página que buscas no se encuentra aquí
            </Typography>
            <Typography
              align="center"
              color="textPrimary"
              variant="subtitle1"
            >
              Probaste alguna ruta (url) mal escrita o visitaste esta página por error.
              De todas maneras, utiliza la navegación para volver.
            </Typography>
            <Button
              component="a"
              startIcon={(<ArrowBackIcon fontSize="small" />)}
              sx={{ mt: 3 }}
              variant="contained"
              onClick={() => navigate("/")}
            >
              Volver al home
            </Button>
          </Paper>
        </Grid>

      </Grid>
    </Container>
  );
}

export default NoMatch;