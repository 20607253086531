import { pathToUrl } from "../../utils/urlGenerator";
import {
  OcupacionInterface,
  PaginatedOcupacionInterface,
} from "../../interfaces/OcupacionInterface";
import { apiRoutes } from "./apiRoutes";
import { useReactQuery } from "stj-components";

const { useFetch } = useReactQuery;

export const useGetOcupaciones = (queryParams?: any) =>
  useFetch<OcupacionInterface[]>(pathToUrl(apiRoutes.ocupaciones), queryParams);

export const useGetPaginatedOcupaciones = (queryParams: any) =>
  useFetch<PaginatedOcupacionInterface>(
    pathToUrl(apiRoutes.ocupaciones, {}),
    queryParams
  );

export const useGetOcupacion = (id: number) =>
  useFetch<OcupacionInterface>(pathToUrl(apiRoutes.ocupacionesById, { id }));
