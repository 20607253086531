import * as Yup from "yup";
import { TextInput, SelectInput } from "stj-components";
import { CircularProgress, Grid } from "@mui/material";
import { UpdatePostulanteSinConfirmarInterface } from "../../../../interfaces/PostulanteInterface";
import { useGetGeneros } from "../../../../services/api/generos";


export const UpdateValidationSchemaPostulanteSinConfirmar = Yup.object({
    apellido: Yup.string().required("Se requiere apellido"),
    nombre: Yup.string().required("Se requiere nombre"),
    documento: Yup.number().required("Se requiere número de documento"),
    email: Yup.string()
        .email("Email incorrecto")
        .matches(/@gmail\.com$/i, "Ingrese un email de gmail.com")
        .required("Se requiere un email"),
    fecha_nacimiento: Yup.date()
        .test(
            "fecha_nacimiento",
            "Tiene que ser mayor de 18 años.",
            function (value) {
                if (!value) {
                    return false; // Handles cases where the date is null or undefined
                }
                const fechaNac = new Date(value);
                const today = new Date();
                const age = today.getFullYear() - fechaNac.getFullYear();

                // Check if the birthday has already occurred this year
                const hasBirthdayPassed =
                    today.getMonth() > fechaNac.getMonth() ||
                    (today.getMonth() === fechaNac.getMonth() &&
                        today.getDate() >= fechaNac.getDate());

                const valid = age > 18 || (age === 18 && hasBirthdayPassed);
                return valid;
            }
        )
        .required("Se requiere fecha de nacimiento"),
    nacionalidad: Yup.string().required("Se requiere nacionalidad"),
    genero_id: Yup.number().required("Seleccione género"),
    ocupacion_id: Yup.number().required("Seleccione ocupación"),
    telefono: Yup.string()
        .matches(
            /^[0-9]{6,10}$/,
            "El campo del teléfono debe tener entre 6 y 10 caracteres"
        )
        .required("Se requiere un teléfono"),
    domicilio: Yup.string().required("Se requiere dirección"),
    localidad: Yup.string().required("Se requiere localidad"),
    provincia: Yup.string().required("Se requiere provincia"),

    observaciones: Yup.string().nullable(),
});

export const formInitialValuesPostulanteSinConfirmar: UpdatePostulanteSinConfirmarInterface = {
    apellido: "",
    nombre: "",
    documento: 0,
    email: "",
    fecha_nacimiento: "",
    nacionalidad: "",
    genero_id: 0,
    ocupacion_id: 0,
    telefono: "",
    domicilio: "",
    localidad: "",
    provincia: "",
    observaciones: "",
}

export const FormPostulanteSinConfirmar = () => {
    const { data: generos, isError: isErrorGeneros } =
        useGetGeneros();

    return (
        <Grid
            style={{ marginTop: '0.5rem' }}
            paddingTop={0}
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
        >
            <Grid item xs={6}>
                <TextInput
                    label="Apellido"
                    name="apellido"
                    type="text"
                    placeholder=""
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item xs={6}>
                <TextInput
                    label="Nombre"
                    name="nombre"
                    type="text"
                    placeholder=""
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item xs={3}>
                <TextInput
                    label="Documento"
                    name="documento"
                    type="text"
                    placeholder=""
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item xs={3}>
                <TextInput
                    label="Fecha de nacimiento"
                    name="fecha_nacimiento"
                    type="date"
                    placeholder=""
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item xs={3}>
                <TextInput
                    label="Nacionalidad"
                    name="nacionalidad"
                    type="text"
                    placeholder=""
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item xs={3}>
                {generos ? (
                    <SelectInput
                        label="Género"
                        options={
                            generos?.map((genero) => ({
                                label: genero.descripcion,
                                value: genero.id,
                            })) ?? []
                        }
                        name="genero_id"
                    />
                ) : isErrorGeneros ? (
                    <div>Error cargando géneros</div>
                ) : (
                    <CircularProgress />
                )}
            </Grid>

            <Grid item xs={8}>
                <TextInput
                    label="Domicilio"
                    name="domicilio"
                    type="text"
                    placeholder=""
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item xs={4}>
                <TextInput
                    label="Teléfono"
                    name="telefono"
                    type="text"
                    placeholder=""
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item xs={6}>
                <TextInput
                    label="Localidad"
                    name="localidad"
                    type="text"
                    placeholder=""
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item xs={6}>
                <TextInput
                    label="Provincia"
                    name="provincia"
                    type="text"
                    placeholder=""
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item xs={12}>
                <TextInput
                    label="Email"
                    name="email"
                    type="text"
                    placeholder=""
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item xs={12}>
                <hr style={{ width: '100%' }} />
            </Grid>

            <Grid item xs={12}>
                <TextInput
                    label="Observaciones"
                    name="observaciones"
                    type="text"
                    placeholder=""
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
        </Grid>
    );
}
