import { useEffect, useMemo, useState } from "react";
import {
  useGetPaginatedPostulantes, useUpdatePostulante, useDeletePostulante
} from "../../../services/api/postulantes";
import GenericCrud from "../../../components/crud/GenericCrud";
import { Alert, AlertTitle, CircularProgress, Grid } from "@mui/material";
import {
  columnsPostulantesConfirmados,
  columnsPostulanteCSV,
} from "./columnsPostulantesConfirmados";
import { ExportMenu } from "../../../components/ExportToolbar/ExportMenu";
import { GridSortModel } from "@mui/x-data-grid";
import {
  formInitialValuesPostulanteConfirmado,
  FormPostulanteConfirmado,
  UpdateValidationSchemaPostulanteConfirmado
} from "./forms/FormPostulanteConfirmado";
import SearchPostulante from "./SearchPostulante";

interface ListPostulantesConfirmadosProps {
  convocatoriaId: string;
}

const ListPostulantesConfirmados = (
  props: ListPostulantesConfirmadosProps
) => {
  const { convocatoriaId } = props;

  const [queryParams, setQueryParams] = useState({});
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sort, setSort] = useState<string>("");

  const queryOptions = useMemo(
    () => ({
      page: page + 1,
      page_size: pageSize,
      sort: sort,
      include: "genero,media,ocupacion,perfiles,perfiles_postulantes.media,perfiles_postulantes.perfil",
      "filter[convocatoria_id]": convocatoriaId,
      "filter[inscripcionConfirmada]": "true",
      ...queryParams,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, pageSize, sort, queryParams]
  );

  const handleSort = (sortModel: GridSortModel) => {
    if (sortModel && sortModel.length === 1) {
      const sortDirection: string = sortModel[0].sort === "desc" ? "-" : "";
      const sorteableAttr: string =
        columnsPostulantesConfirmados.filter(
          (col) => col.field === sortModel[0].field
        )[0].sorteableColumnFilter ?? "";
      const querySort: string = `${sortDirection}${sorteableAttr}`;
      setSort(querySort);
    }
  };

  const {
    data: postulantes,
    isLoading: isLoadingPostulantes,
    isError: isErrorPostulantes,
    refetch: refetchPostulantes,
  } = useGetPaginatedPostulantes(queryOptions);

  const deleteMutation = useDeletePostulante();

  const [rowCountState, setRowCountState] = useState(postulantes?.total || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState: any) =>
      postulantes?.total !== undefined ? postulantes?.total : prevRowCountState
    );
  }, [postulantes?.total, setRowCountState]);

  const handleOnSubmitFilter = (data: any) => {
    const { nombre, apellido, documento, email, perfilIds } = data;

    const filters = {
      "filter[nombre]": nombre,
      "filter[apellido]": apellido,
      "filter[documento]": documento,
      "filter[email]": email,
      "filter[perfil]": perfilIds?.join(",") ?? "",
    };

    setQueryParams(filters);
  };

  // useEffect(() => { console.log(postulantes) }, [postulantes]);

  return (
    <>
      <Grid item xs={12} mb={2}>
        <Alert severity="info">
          <AlertTitle>
            En esta pestaña se muestran los postulantes que ya han confirmado su
            correo electrónico.
            <br />
            Están a la espera de recibir el email para continuar con el examen.
          </AlertTitle>
        </Alert>
      </Grid>
      <Grid container item xs={12} marginBottom={2}>
        <SearchPostulante handleOnSubmit={handleOnSubmitFilter} />
      </Grid>

      {postulantes && (
        <>
          <Grid container item xs={12} justifyContent={"right"}>
            <ExportMenu
              reactQueryHook={useGetPaginatedPostulantes}
              queryOptions={queryOptions}
              columns={columnsPostulanteCSV}
              fileName="Lista de postulantes"
              label="Exportar CANVAS"
            />
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <GenericCrud
                data={postulantes.data}
                columns={columnsPostulantesConfirmados}
                viewOnClick={true}
                editOnClick={false}
                form={<FormPostulanteConfirmado />}
                formValidation={UpdateValidationSchemaPostulanteConfirmado}
                formInitialValues={formInitialValuesPostulanteConfirmado}
                hideAddBtn={true}
                addMutation={() => {
                  console.log("No se pueden crear postulantes..");
                }}
                useEditMutation={useUpdatePostulante}
                deleteMutation={deleteMutation}
                refetch={refetchPostulantes}
                page={page}
                pageSize={pageSize}
                rowCount={rowCountState}
                setPage={setPage}
                setPageSize={setPageSize}
                handleSort={handleSort}
              />
            </Grid>
          </Grid>
        </>
      )}

      {isErrorPostulantes && <div>Error cargando</div>}

      {isLoadingPostulantes && <CircularProgress />}
    </>
  );
};

export default ListPostulantesConfirmados;
