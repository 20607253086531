import { pathToUrl } from "../../utils/urlGenerator";
import {
  ActividadInterface,
  PaginatedActividadesInterface,
} from "../../interfaces/ActividadInterface";
import { apiRoutes } from "./apiRoutes";
import { useReactQuery } from "stj-components";

const { useDelete, useFetch, usePost, useUpdate } = useReactQuery;

export const useGetActividades = (queryParams?: any) =>
  useFetch<ActividadInterface[]>(pathToUrl(apiRoutes.actividades), queryParams);

export const useGetPaginatedActividades = (queryParams: any) =>
  useFetch<PaginatedActividadesInterface>(
    pathToUrl(apiRoutes.actividades, {}),
    queryParams
  );

export const useGetActividad = (id: number) =>
  useFetch<ActividadInterface>(pathToUrl(apiRoutes.actividadesById, { id }));

export const useCreateActividad = () =>
  usePost<ActividadInterface, ActividadInterface>(
    pathToUrl(apiRoutes.actividades)
  );

export const useUpdateActividad = (id: number) =>
  useUpdate<any, any>(pathToUrl(apiRoutes.actividadesById, { id }));

export const useDeleteActividad = () =>
  useDelete<ActividadInterface>(pathToUrl(apiRoutes.actividades));
